import { Box, Button, Text } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { ArrowLeft, Home } from 'tabler-icons-react'

export default function Custom404() {
    const router = useRouter()
    return (
        <Box px={15} w="100%" py={60} maw={1100} className="flex aic jcc fdc" mx="auto">
            <Text lh={1} fw={900} fz={65}>404</Text>
            <Text mt={10} lh={1} c="dimmed" span fz={35} fw={700}>Page Not Found</Text>
            <Text mt={15} fw={500} c="dimmed" ta="center" maw={400}>We searched far and wide, but the page you were looking for could not be found.</Text>
            <Box mt={15} className="flex" style={{ gap: 10 }}>
                <Button leftSection={<ArrowLeft size={18} />} variant={"default"} onClick={() => router.back()}>Go Back</Button>

                <Link href={"/"}>
                    <Button leftSection={<Home size={18} />}>Go Home</Button>
                </Link>
            </Box>
        </Box>
    )
}
